import React, { FC } from "react";
import classNames from "classnames";

import styles from "./container.module.scss";

interface ContainerProps {
  title: string;
  className?: string;
  imgUrl?: string;
  clickable?: boolean;
  url?: string;
  removeArrowOnMobile?: boolean;
  iconWidth?: number;
  iconHeight?: number;
  hoverActive?: boolean;
  isProjectType?: boolean;
  gif?: string;
}

const Container: FC<ContainerProps> = ({
  title,
  className,
  imgUrl,
  clickable,
  url,
  removeArrowOnMobile,
  iconHeight = 60,
  iconWidth = 60,
  hoverActive,
  gif,
  isProjectType,
}) => {
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <div
        className={classNames(styles.container, className, {
          [styles.clickable]: clickable,
          [styles.hoverActive]: hoverActive,
          [styles.projectType]: isProjectType,
        })}
        onClick={() => clickable && openInNewTab(url || "")}
      >
        <div className={styles.nameWrapper}>
          <span
            className={classNames(styles.arrow, {
              [styles.removeArrowOnMobile]: removeArrowOnMobile,
            })}
          />
          <span className={styles.name}>{title}</span>
        </div>
        <img src={imgUrl} width={iconWidth} height={iconHeight} alt={title} />
        {isProjectType && (
          <div className={styles.gifWrapper}>
            <video width="220" autoPlay muted loop className={styles.video}>
              <source src={gif} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </>
  );
};

export default Container;
